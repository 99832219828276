<template>
  <div>
    <div class="mb-3 ml-3">
      <div class="row">
        <div class="col-6 pl-0">
          <h2>Promotion</h2>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <a-button
            icon="plus-square"
            class="add__button"
            type="primary"
            @click="$router.push({ name: 'add-voucher', query: $route.query })"
          >
            <span class="fe fe-plus-circle" /> Add New Voucher
          </a-button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div v-if="isSearch" style="width: 100%">
          <a-tabs>
            <a-tab-pane tab="Search Result" />
            <div
              slot="tabBarExtraContent"
              :style="{ cursor: 'pointer' }"
              @click="handleCloseTabSearch"
            >
              <a-icon type="close" />
            </div>
          </a-tabs>
        </div>
        <a-form-model ref="ruleForm" :model="form">
          <div class="row">
            <div class="col-4 mt-2">
              <a-input-search
                placeholder="Voucher Code"
                :value="valueSearch"
                @change="onChangeKeywordSearch"
              />
            </div>
            <div class="col-4 mt-1">
              <div>
                <a-form-model-item
                  prop="validity"
                  :rules="[{
                    type: 'array',
                    message: 'Please select range date time',
                    trigger: 'change',
                  }]"
                >
                  <a-range-picker v-model="form.validity" />
                </a-form-model-item>
              </div>
            </div>
            <div class="col col-lg-1 mt-1">
              <a-form-model-item>
                <a-button type="primary" @click="handleSearch">
                  Search
                </a-button>
              </a-form-model-item>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="card-body">
        <a-table
          :columns="columns"
          :data-source="voucherList"
          :pagination="pagination"
          :row-key="data => data.id"
          :loading="isLoadingTable"
          class="table-discounts"
          @change="handleTableChange"
        >
          <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
            <p v-if="record.value_type === `percentage`">
              Disc {{ record.voucher_value }}% off entire order. With minimum
              transaction {{ record.min_transaction | currency }}. Maximum
              discount {{ record.max_discount | currency }}.
            </p>
            <p v-if="record.value_type === `amount`">
              Disc {{ record.voucher_value | currency }} off entire order. With
              minimum transaction {{ record.min_transaction | currency }}.
              Maximum discount {{ record.max_discount | currency }}.
            </p>

            <div>
              <h6>Availability:</h6>
              <div>Total: {{ record.qty }}</div>
              <div>Available: {{ record.available_booked }}</div>
              <div
                style="cursor: pointer; color: blue"
                @click="showVoucherDetail(record)"
              >
                Used: {{ record.used }}
              </div>
              <div>Booked: {{ record.booked }}</div>
            </div>

            <div class="mt-3">
              <h6>Voucher Periode</h6>
              <div>
                {{ changeDateFormat(record.valid_from) }} -
                {{ changeDateFormat(record.valid_until) }}
              </div>
            </div>

            <div v-if="record.voucher_type_id === 2" class="mt-3">
              <h6>List Product to Discount:</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="(product, index) in JSON.parse(record.reference_list)"
                  :key="index"
                >
                  {{ listProductDiscount(product) }}
                </li>
              </ul>
            </div>
            <div v-if="record.voucher_type_id === 3" class="mt-3">
              <h6>Courier to Discount:</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="(courier, index) in JSON.parse(record.courier_list)"
                  :key="index"
                >
                  {{ listCourierDiscount(courier) }}
                </li>
              </ul>
            </div>
            <div v-if="record.voucher_type_id === 4" class="mt-3">
              <h6>Brand to Discount:</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="(brand, index) in JSON.parse(record.reference_list)"
                  :key="index"
                >
                  {{ listBrandDiscount(brand) }}
                </li>
              </ul>
            </div>
          </div>
          <span slot="voucher_type" slot-scope="text, record">
            <div v-if="record.voucher_type_id === 1">Transaction Voucher</div>
            <div v-if="record.voucher_type_id === 2">
              <div class="text-bold">Product Item Voucher</div>
              <div class="text-muted">
                ({{ JSON.parse(record.reference_list).length }} product)
              </div>
            </div>
            <div v-if="record.voucher_type_id === 3">Shipping Voucher</div>
            <div v-if="record.voucher_type_id === 4">Brand Voucher</div>
          </span>
          <template slot="action_button" slot-scope="text, record">
            <a-icon
              :id="`delete-${record.id}`"
              type="delete"
              class="mr-3 ml-3"
              style="cursor: pointer"
              @click.prevent="showConfirm(record.id)"
            />
            <router-link
              v-if="
                checkStatusVoucher(record.valid_from, record.valid_until) !==
                  'expired'
              "
              :to="{
                name: 'edit-voucher',
                query: { ...$route.query, uuid: record.id },
              }"
            >
              <a-icon :id="`edit-${record.id}`" type="edit" class="mr-3 ml-3" />
            </router-link>
          </template>
          <span slot="status" slot-scope="text, record">
            <a-tag
              v-if="
                checkStatusVoucher(record.valid_from, record.valid_until) ===
                  'expired'
              "
              color="red"
            >
              {{ checkStatusVoucher(record.valid_from, record.valid_until) }}
            </a-tag>

            <a-tag
              v-if="
                checkStatusVoucher(record.valid_from, record.valid_until) ===
                  'coming soon'
              "
              color="blue"
            >
              {{ checkStatusVoucher(record.valid_from, record.valid_until) }}
            </a-tag>

            <a-tag
              v-if="
                checkStatusVoucher(record.valid_from, record.valid_until) ===
                  'running'
              "
              color="green"
            >
              {{ checkStatusVoucher(record.valid_from, record.valid_until) }}
            </a-tag>
          </span>
        </a-table>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { Modal } from 'ant-design-vue'

export default {
  data() {
    return {
      isDiscountCode: false,
      isAutomaticDiscount: false,
      fullPage: true,
      page: 1,
      limit: 20,
      position: {
        x: 0,
        y: 0,
      },
      isSearch: false,
      valueSearch: '',
      form: {
        validity: null,
      },
    }
  },
  computed: {
    ...mapState('web_platform_channel/discounts', {
      isLoadingDiscount: state => state.isLoadingDiscount,
      voucherList: state => state.voucherList,
      search: state => state.search,
      columns: state => state.columns,
      isLoadingTable: state => state.isLoadingTable,
      pagination: state => state.pagination,
      rangeDate: state => state.rangeDate,
      keywordSearch: state => state.keywordSearch,
      tableActive: state => state.tableActive,
      productList: state => state.productList,
      brands: state => state.brands,
      courierList: state => state.courierList,
    }),
  },
  created() {
    this.$store.dispatch('web_platform_channel/discounts/GETVOUCHERLIST', {
      page: this.page,
      limit: this.limit,
      ...this.$route.params,
      ...this.$route.query,
    })
    this.$store.dispatch('web_platform_channel/discounts/getProductList', {
      ...this.$route.query,
      ...this.$route.params,
    })
    this.$store.dispatch('web_platform_channel/discounts/getResellerList', {
      ...this.$route.query,
      ...this.$route.params,
    })
    this.$store.dispatch('web_platform_channel/discounts/getBrands', {
      ...this.$route.query,
      ...this.$route.params,
    })
    this.$store.dispatch('web_platform_channel/discounts/GETCOURIERLIST', {
      ...this.$route.query,
      ...this.$route.params,
    })
  },
  methods: {
    ...mapMutations('web_platform_channel/discounts', [
      'SET_TABLE_ACTIVE',
      'SET_RANGE_DATE',
      'SET_KEYWORD_SEARCH',
    ]),

    ...mapActions('web_platform_channel/discounts', [
      'getVoucherList',
      'searchDiscountCode',
      'deleteVoucher',
      'searchAutomaticDiscount',
      'searchVoucher',
    ]),

    handleCloseTabSearch() {
      this.isSearch = false
      this.valueSearch = ''
      this.$refs.ruleForm.resetFields('validity')
      this.$store.dispatch('web_platform_channel/discounts/GETVOUCHERLIST', {
        page: this.page,
        limit: this.limit,
        ...this.$route.params,
        ...this.$route.query,
      })
    },

    changeDateFormat(value) {
      return this.$moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
    },

    handleDeleteVoucher(param) {
      this.deleteVoucher(param)
    },

    handleTableChange(pagination) {
      this.page = pagination.current
      this.$store.dispatch('web_platform_channel/discounts/GETVOUCHERLIST', {
        page: pagination.current,
        limit: this.limit,

        ...this.$route.params,
        ...this.$route.query,
      })
    },

    listProductDiscount(value) {
      const allProduct = this.productList
      for (const i in allProduct) {
        if (allProduct[i].uuid === value) {
          return allProduct[i].product_title
        }
      }
    },

    listBrandDiscount(value) {
      const allbrand = this.brands
      for (const i in allbrand) {
        if (allbrand[i].uuid === value) {
          return allbrand[i].brand_title
        }
      }
    },

    listCourierDiscount(value) {
      const courier = this.courierList
      for (const i in courier) {
        if (courier[i].value === value) {
          return courier[i].label
        }
      }
    },

    showConfirm(value) {
      Modal.confirm({
        title: `Do you want to delete these voucher? ${value}`,
        onOk: () => {
          this.$store
            .dispatch('web_platform_channel/discounts/deleteVoucher', {
              uuid: value,

              ...this.$route.params,
              ...this.$route.query,
            })
            .then(({ data }) => {
              if (data.statusCode === 200) {
                const { current } = this.pagination
                this.$notification.success({
                  message: this.$i18n.t('notification.successDeleteVoucher'),
                })
                return this.$store.dispatch(
                  'web_platform_channel/discounts/GETVOUCHERLIST',
                  {
                    page: current,
                    limit: 20,

                    ...this.$route.params,
                    ...this.$route.query,
                  },
                )
              }
            })
        },
        onCancel() {},
        class: 'test',
      })
    },

    checkStatusVoucher(from, until) {
      /** Check expired */
      if (this.$moment(this.changeDateFormat(until)).isBefore(this.$moment())) {
        return 'expired'
      }
      /** Check Coming Soon */
      if (this.$moment(this.changeDateFormat(from)).isAfter(this.$moment())) {
        return 'coming soon'
      }
      /** Check Running Voucher */
      if (
        this.$moment().isBetween(
          this.changeDateFormat(from),
          this.changeDateFormat(until),
        )
      ) {
        return 'running'
      }
    },

    showVoucherDetail(record) {
      this.position.x = window.scrollX
      this.position.y = window.scrollY

      this.$router.push({
        name: 'discounts.view',
        query: {
          ...this.$route.query,
          uuid: record.id,
        },
      })
    },

    onChangeKeywordSearch(e) {
      this.valueSearch = e.target.value
    },

    handleSearch(e) {
      e.preventDefault()
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return
        if (!this.form.validity && this.valueSearch.length === 0) {
          this.$notification.error({
            message: this.$i18n.t('notification.failedSearchVoucher'),
          })
        } else {
          const from =
            this.form.validity &&
            `${this.$moment(this.form.validity[0]).format(
              'YYYY-MM-DD 00:01:00.000',
            )}${this.$moment().format('Z')}`
          const to =
            this.form.validity &&
            `${this.$moment(this.form.validity[1]).format(
              'YYYY-MM-DD 23:59:00.000',
            )}${this.$moment().format('Z')}`
          this.isSearch = true
          this.$store.dispatch(
            'web_platform_channel/discounts/GETVOUCHERLIST',
            {
              voucher_code: this.valueSearch,
              page: this.page,
              limit: this.limit,
              from,
              to,
              ...this.$route.params,
              ...this.$route.query,
            },
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
.table-discounts {
  tr {
    > th {
      padding: 10px !important;
      font-weight: bold !important;
    }
  }
}
</style>
